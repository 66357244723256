import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Intercom: any;
  }
}
@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  private readonly platformId = inject(PLATFORM_ID);
  newMessage() {
    if (isPlatformBrowser(this.platformId)) {
      window.Intercom('showNewMessage');
    }
  }

  initMessenger() {
    if (isPlatformBrowser(this.platformId)) {
      window.Intercom('boot', {
        app_id: 'iagcjkqo'
      });
    }
  }

  hideMessenger() {
    if (isPlatformBrowser(this.platformId)) {
      window.Intercom('update', {
        hide_default_launcher: true
      });
    }
  }

  updateMessanger() {
    if (isPlatformBrowser(this.platformId)) {
      window.Intercom('update', {
        hide_default_launcher: false
      });
    }
  }
}
